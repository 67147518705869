@import "./assets/style/animations.scss";
@import "./assets/style/bootstrap-grid.css";
@import "./assets/style/colors.scss";
@import "./assets/style/custom.scss";
@import "./assets/fonts/fonts.css";
@import "./assets/style/system.scss";

body {
  margin: 0;
  font-family: "Texta", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  margin: 0px;
}
