@import "colors.scss";
.system-page {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  text-align: center;
  background: $primaryColor no-repeat;
  // background: $primaryLightColor no-repeat;
  background-size: cover;
  padding: 30px;

  .system-box {
    display: table-cell;
    vertical-align: middle;
    margin: auto;
    width: 360px;
    // border-radius: 15px 3px 15px 3px;
    &__container {
      // background-color: #f9fafb;
      // border: 3px solid $white;
      border-radius: 10px;
      padding: 20px 35px;
      // box-shadow: 10px 10px 10px -5px rgba(255, 217, 0, 0.2), 40px 40px 80px -40px rgba(116, 116, 116, 0.4);
      transition: all ease 200ms;
      // &.flat {
      //   box-shadow: 1px 1px 3px -1px rgba(255, 217, 0, 0.2), 2px 2px 10px -5px rgba(116, 116, 116, 0.4);
      // }
      &-logo {
        color: "#fff";
        display: "flex";
        align-items: "center";
        justify-content: "center";
      }
    }

    &__signin {
      width: 540px;
    }

    .logo {
      margin: -60px auto 20px auto;
      svg {
        height: 40px;
        fill: black;
      }
    }
    form {
      max-width: 100%;
    }
    // .input-container {
    //   width: 100%;
    //   margin: 0 auto;
    //   input {
    //     width: 100%;
    //     border: none !important;
    //   }
    //   &-field {
    //     input {
    //       background: $primaryLightColor;
    //       color: #fff;
    //       &.Mui-focused {
    //         background: $primaryLightColor;
    //       }
    //     }
    //     label {
    //       color: #fff;
    //       font-weight: bold;
    //     }
    //   }
    //   &-button {
    //     background-color: $secondaryColor;
    //     color: #fff;
    //     & .Mui-disabled {
    //       background-color: $primaryLightColor !important;
    //       color: $primaryColor !important;
    //     }
    //   }
    //   &-forbidden-description {
    //     color: #fff;
    //     font-size: 1.5em;
    //     margin: 5vh 0;
    //   }
    // }

    .login-forgot {
      padding-left: 0;
      padding-right: 0;
      width: auto !important;
    }
    .message-error {
      // font-family: var(--text-primary-font);
      // font-size: 1.3em;
      // margin: 15px 0;
      // font-weight: lighter;
      font-family: var(--text-primary-font);
      font-size: 1.2em;
      margin: 15px 0;
      font-weight: normal;
      color: #777;
      text-align: justify;
    }
    .code-error {
      margin: 15px 0;
      font-size: 1em;
      color: var(--btn-dark-text);
      font-family: monospace;
    }
    .message-head {
      font-size: 1.5em;
      font-family: var(--text-primary-font);
      margin: 30px 0;
    }
    .message {
      margin: 20px auto;
      max-width: 500px;
      &.error-box {
        font-size: 0.8em;
        background-color: rgb(31, 66, 95);
        padding: 12px 20px;
        border-radius: 4px;
        text-align: left;
        @media (max-width: 767px) {
          width: 300px;
          overflow-wrap: anywhere;
        }
        .error-message {
          color: rgb(148, 192, 156);
          font-family: monospace;
        }
      }
    }
    .action {
      width: 300px;
      margin: 20px auto 0 auto;
      button {
        width: 100%;
      }
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.general-error {
  &--container {
    height: 100vh;
    width: 100vw;
    background-image: url(../../assets/images/copertina-login.svg);
    background-position: center; /* Center the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  &--logo-container {
    height: 30vh;
    padding-bottom: 10px !important;
  }
  &--cube-container {
    padding-top: 10px !important;
    height: 70vh;
  }
  &--cube {
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    height: 40vh;
    min-height: 200px;
    -webkit-box-shadow: -4px 5px 15px 4px #00000081;
    box-shadow: -4px 5px 15px 4px #00000081;
  }
  &--content {
    * {
      display: inline;
    }
    &-title {
      color: rgb(3, 85, 153);
      h2 {
        margin: 0;
      }
    }
    &-description {
      color: black;
    }
  }
  &--exit-button {
    height: fit-content;
  }
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.w-fit-content {
  width: fit-content;
}

.h-fit-content {
  height: fit-content;
}

.w-25 {
  width: 25%;
}

.w-100 {
  width: 100%;
}

.w-24px {
  width: 24px;
}

.h-50 {
  height: 50%;
}

.h-90 {
  height: 90%;
}

.h-100 {
  height: 100% !important;
}

.max-parent {
  height: 100%;
  width: auto;
  max-width: 100%;
}

.w-80vw {
  width: 80vw;
}

.w-45vw {
  width: 45vw;
}

.w-auto {
  width: auto !important;
}

.h-30vh {
  height: 30vh;
}

.max-width-100 {
  max-width: 100%;
}

.margin-3px {
  margin: 3px;
}

.margin-5px {
  margin: 5px;
}

.align-self-center {
  align-self: center;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-center {
  justify-content: center;
}

.flex {
  display: flex;
}

.absolute-top-0 {
  position: absolute;
  top: 0;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.flex-grow-1 {
  flex-grow: 1;
}

.background-none {
  background: none;
}

.display-inline {
  display: inline !important;
}

.disabledCard {
  background-color: rgba(51, 51, 51, 0.1) !important;
  cursor: not-allowed !important;
  box-shadow: -3px -3px 3px 0px rgba(138, 138, 138, 0.452) inset !important;
  -webkit-box-shadow: -3px -3px 3px 0px rgba(138, 138, 138, 0.452) inset !important;
  -moz-box-shadow: -3px -3px 3px 0px rgba(138, 138, 138, 0.452) inset !important;
  svg {
    filter: grayscale(100%) !important;
  }
}
