$var: (
  "primary": #363636,
  "primary-light": #898989,
  "primary-dark": #292929,
  "secondary": #3c5680,
  "text-on-primary": #ffffff,
  "text-on-secondary": #000000,
  "background": #ececec,
  "error": #ff0000,
);

:root {
  @each $name, $color in $var {
    --#{$name}: #{$color};

    $existsLight: map-get($var, "#{$name}-light");

    @if $existsLight {
      --#{$name}-light-computed: #{lighten($color, 20%)};
    } @else {
      --#{$name}-light: #{lighten($color, 20%)};
    }

    $existsDark: map-get($var, "#{$name}-dark");

    @if $existsDark {
      --#{$name}-dark-computed: #{darken($color, 20%)};
    } @else {
      --#{$name}-dark: #{darken($color, 20%)};
    }
  }
}

$pageBackgroundColor: map-get($var, "background");
$pageBackgroundColorPrimary: #fff;
// $pageBackgroundColorPrimary: map-get($var, 'primary');
$background: map-get($var, "background");
// $lightBackgroundColor: #ececec;

$a: #3c77a3;

$primaryColor: #363636;
$primaryLightColor: #898989;
$primaryDarkColor: #292929;
$secondaryColor: #3c5680;
$primaryTextColor: #ffffff;
$secondaryTextColor: #000000;
$background: #ececec;
$bgModal: #fff;

$fadedColor: #eff5ff;
$halfFadedColor: #ebebeb;

$accentColor: #1d69e3;

$error: #ff0000;
$success: #27f331;
$warning: #e2e624;
$info: #42a6be;
$white: #fff;
$darkColor: #282828;
$lightGrey: #ccc;
$gradient: linear-gradient(
  90deg,
  #e5005b 0%,
  #df035d 18%,
  #cf0a64 41%,
  #b4166f 68%,
  #8e287f 96%,
  #892a81 100%
);
$processedColor: #531dab;
$processedBackground: #f9f0ff;
$processedBorder: #d3adf7;
$submittedColor: #096dd9;
$submittedBackground: #e6f7ff;
$submittedBorder: #91d5ff;
$processingColor: #1d39c4;
$processingBackground: #f0f5ff;
$processingBorder: #adc6ff;
$acceptedColor: #00673a;
$acceptedBackground: #cffce5;
$acceptedBorder: #00673a;
$processErrorColor: #d33203;
$processErrorBackground: #ffe3da;
$processErrorBorder: #d33203;
$rejectedColor: #70000f;
$rejectedBackground: #ffdbe0;
$rejectedBorder: #70000f;
$ledBaseShadow: rgba(0, 0, 0, 0.2);
$ledRedShadowFirst: #aa1414;
$ledRedShadowSecond: rgba(255, 0, 0, 0.568);
$ledGreenShadowFirst: #179228;
$ledGreenShadowSecond: rgba(11, 153, 46, 0.5);
$ledYellowShadowFirst: #b7b928;
$ledYellowShadowSecond: #b6b9289a;
$ledBlueShadowFirst: #308ca3;
$ledBlueShadowSecond: #308ca3b4;

@mixin shadow($scolor: #000, $size) {
  @if $size == "sm" {
    box-shadow: 0.5px 1px 1px rgba($scolor, 0.7);
  }
  @if $size == "md" {
    box-shadow: 1px 2px 2px rgba($scolor, 0.333),
      2px 4px 4px rgba($scolor, 0.333), 3px 6px 6px rgba($scolor, 0.333);
  }
  @if $size == "lg" {
    box-shadow: 1px 2px 2px rgba($scolor, 0.2), 2px 4px 4px rgba($scolor, 0.2),
      4px 8px 8px rgba($scolor, 0.2), 8px 16px 16px rgba($scolor, 0.2),
      16px 32px 32px rgba($scolor, 0.2);
  }
}

.bshadow {
  $sgrey: #3366cc;
  &-sm {
    @include shadow($sgrey, "sm");
  }
  &-md {
    @include shadow($sgrey, "md");
  }
  &-lg {
    @include shadow($sgrey, "lg");
  }
}

.white {
  color: #fff;
}

.blue-text {
  color: #127ec9;
}

.black-text {
  color: #000000;
}

.red-text {
  color: red;
}

.dark-grey-text {
  color: #29313a;
}

.red-background {
  background-color: red;
}
