* {
  font-family: "Texta", sans-serif !important;
}

.white-link {
  color: $white;
  text-decoration: none;
}

.yellow-link {
  color: #f3dc0c;
  text-decoration: none;
}

.admin {
  // Horizontal part of the menu (AdminLayout)
  &-header {
    // background: map-get($var, "primary");
    // color: map-get($var, "text-on-primary");

    // Background image on the horizontal menu
    background-image: url(../../assets/images/header-small.svg);
    background-position: center; /* Center the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    display: flex;
    align-items: flex-end !important; /* Vertically align the menu elements on the bottom of the menu */

    color: $white;

    &-profile {
      color: map-get($var, "text-on-primary");
    }
    &-siderMenu {
      color: map-get($var, "text-on-primary");
    }
  }

  // Big horizontal header
  &-header-big {
    height: 20vh;
    // Background image on the horizontal menu
    background-image: url(../../assets/images/header-big.svg);
    background-position: center; /* Center the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    display: flex;
    align-items: flex-end !important; /* Vertically align the menu elements on the bottom of the menu */

    color: $white;

    &-profile {
      color: map-get($var, "text-on-primary");
    }
    &-siderMenu {
      color: map-get($var, "text-on-primary");
    }
  }

  &-main-title {
    // margin: 30px 24px;
    color: map-get($var, "primary");
    font-family: "Texta Book", sans-serif !important;
    font-style: normal;
    font-weight: 350;
    // border-bottom: 1px solid map-get($var, "background");
    padding-left: 0px !important;
    margin-left: 8px !important;
    h2 {
      font-size: 2rem;
      margin: 5px;
      margin-bottom: 0px;
      line-height: 2.2rem !important;
    }
    small {
      margin-left: 25px;
    }
    h4 {
      font-size: 1.2rem;
    }
  }

  &-divider-text {
    color: map-get($var, "primary");
    font-size: 1.2rem;
  }

  &-main {
    margin-top: 70px !important;
  }

  &-layout {
    // &-subMenu {
    //   height: fit-content;
    //   border: none;
    //   box-shadow: 2px 2px 4px rgb(70, 70, 70);
    //   .list {
    //     position: fixed;
    //     background: $pageBackgroundColor !important;
    //     ul {
    //       height: calc(100vh - 64px);
    //       width: 200px;
    //       overflow-y: auto;
    //       .selected {
    //         background-color: $background !important;
    //       }
    //     }
    //   }
    //   a {
    //     text-decoration: none !important;
    //     color: #fff;
    //   }
    // }
    &-mainMenu > .MuiDrawer-paperAnchorLeft {
      border: none;
      span {
        color: map-get($var, "primary") !important;
        .MuiTypography-root {
          color: map-get($var, "primary");
          font-weight: bold;
        }
        svg {
          fill: map-get($var, "primary") !important;
        }
        text-decoration: none !important;
      }
      ul {
        .mainSelected {
          color: map-get($var, "secondary");
          .MuiTypography-root {
            color: #127ec9 !important;
          }
          svg {
            fill: map-get($var, "secondary") !important;
          }
        }
      }
    }
    &-box {
      // vertical part of the menù (AdminLayout)
      background-color: $white;
      box-shadow: inset -10px 0 20px -10px darken($color: $pageBackgroundColorPrimary, $amount: 8);
      // background-color: $primaryColor;
      height: 100%;
      padding-top: 110px;
      & > div {
        // hidden horizontal part of the menù (AdminLayout)
        // background-color: $secondaryColor;
        & > button {
          color: #202b35 !important;
        }
      }
    }
  }

  &-open-subMenu {
    padding-left: 220px;
  }

  // Modal Dialog
  &-dialog {
    &-title {
      background-color: #f6f5f5;
      &-string {
        color: #29313a;
        align-self: center;
      }
    }
  }

  &-table {
    &-thead {
      &-row {
        background-color: $background;
        th {
          color: #fff !important;
        }
      }
    }

    &-tbody {
      tr:nth-child(even) {
        background-color: #fff !important;
      }
    }
  }

  &-backIcon {
    position: absolute;
    top: 10px;
    left: 10px;
    // color: #fff;
    font-size: 40px;
  }

  &-landing {
    h1,
    a {
      color: #fff;
    }
  }

  &-recaptcha {
    > div {
      display: flex;
      justify-content: center;
    }
  }

  &-list {
    .ant-tag {
      min-width: 100px;
      text-align: center;
      border-radius: 8px 0;
    }
  }

  &-bitTitle {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h1 {
      position: relative;
      z-index: 1;
      cursor: pointer;
      margin-bottom: 0;
      margin-top: 0;
    }

    h3 {
      position: relative;
      z-index: 1;
      cursor: pointer;
      margin-bottom: 0;
      margin-top: 0;
    }

    &-line {
      width: 2px;
      height: 50%;
      background-color: $secondaryColor;
    }

    &-text {
      margin: auto 0;
      padding: 0 60px 0 0;
      text-align: justify;
      @media (max-width: 576px) {
        padding: 0px;
        width: 100% !important;
      }
    }
  }

  &-user {
    &-landing {
      &__left {
        height: calc(100vh - 150px);
        display: flex;
        @media (max-width: 576px) {
          display: block;
        }
      }

      &__right {
        display: flex;
        @media (max-width: 576px) {
          display: block;
        }
      }
    }

    &-steps {
      &-content {
        height: calc(100% - 110px);
        @media (max-width: 576px) {
          height: initial;
        }

        &__inner {
          height: calc(100% - 60px);
          @media (max-width: 576px) {
            height: initial;
          }
        }

        section {
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

  &-chip {
    min-width: 120px;
    text-align: center;
    border-radius: 8px 0 !important;
    border: 1px solid !important;
    height: 23px !important;

    &--trucated {
      @media (max-width: 576px) {
        max-width: 220px;
      }
    }

    &--PROCESSED,
    &--ADMIN {
      color: $processedColor !important;
      background-color: $processedBackground !important;
      border-color: $processedBorder !important;
    }

    &--SUBMITTED,
    &--APPLICANT {
      color: $submittedColor !important;
      background-color: $submittedBackground !important;
      border-color: $submittedBorder !important;
    }

    &--PROCESSING,
    &--INVESTIGATOR {
      color: $processingColor !important;
      background-color: $processingBackground !important;
      border-color: $processingBorder !important;
    }

    &--ACCEPTED {
      color: $acceptedColor !important;
      background-color: $acceptedBackground !important;
      border-color: $acceptedBorder !important;
    }

    &--PROCESS_ERROR {
      color: $processErrorColor !important;
      background-color: $processErrorBackground !important;
      border-color: $processErrorBorder !important;
    }

    &--REJECTED {
      color: $rejectedColor !important;
      background-color: $rejectedBackground !important;
      border-color: $rejectedBorder !important;
    }
  }

  &-filter {
    &__button {
      &--active {
        text-decoration: underline !important;
      }
    }
  }
}

.Mui-disabled {
  // opacity: 0.3;
  // background-color: $halfFadedColor;
  // color: white;
  opacity: 1;
  background-color: transparent;
  border-color: transparent;

  input {
    -webkit-text-fill-color: #000000 !important;
  }

  .table-pagination {
    color: $primaryLightColor !important;
  }
}

.MuiChip-filled {
  // background-color: $secondaryColor !important;
  color: $primaryDarkColor !important;
  font-weight: bold;
}

.grey-background {
  background-color: #e0e0e0 !important;
}

.table-pagination {
  color: $primaryColor !important;
}

//MUI DATA GRID CUSTOM
.MuiDataGrid {
  &-root {
    border: none !important;
  }
  &-columnHeaders {
    background: transparent !important;
    color: black !important;
    border: none !important;
    min-height: 42px !important;
    max-height: 42px !important;
  }
  &-columnHeader,
  &-columnHeaderDraggableContainer,
  &-columnHeaderTitleContainer,
  &-columnHeaderTitle {
    min-height: 42px !important;
    max-height: 42px !important;
    font-weight: 800 !important;
    font-size: 1.1rem !important;
  }
  &-virtualScroller {
    margin-top: 42px !important;
  }
  &-columnSeparator {
    opacity: 0 !important;
  }
  &-row {
    margin-top: 7.5px !important;
    margin-bottom: 7.5px !important;
    background: #fff;
    box-shadow: 1px 2px 1px 0px rgba(224, 222, 222, 0.452);
    -webkit-box-shadow: 1px 2px 1px 0px rgba(224, 222, 222, 0.452);
    -moz-box-shadow: 1px 2px 1px 0px rgba(224, 222, 222, 0.452);
    border-radius: 6px;
    min-height: 56px !important;
    max-height: 56px !important;
  }
  &-cell {
    min-height: 56px !important;
    max-height: 56px !important;
  }
}

.remove-link-style {
  text-decoration: none !important;
  color: #000000;
}

// .MuiFormLabel-root .Mui-disabled {
//   color: $primaryDarkColor !important;
// }

.scroll-desktop {
  overflow-y: scroll !important;
  @media (max-width: 576px) {
    overflow-y: visible !important;
  }
}

.led {
  margin: 0 auto;
  width: 10px;
  height: 10px;

  border-radius: 50%;
  &-red {
    background-color: $error;
    box-shadow: $ledBaseShadow 0 -1px 7px 1px, inset $ledRedShadowFirst 0 -1px 9px, $ledRedShadowSecond 0 2px 5px;
  }
  &-green {
    background-color: $success;
    box-shadow: $ledBaseShadow 0 -1px 7px 1px, inset $ledGreenShadowFirst 0 -1px 9px, $ledGreenShadowSecond 0 2px 5px;
  }
  &-yellow {
    background-color: $warning;
    box-shadow: $ledBaseShadow 0 -1px 7px 1px, inset $ledYellowShadowFirst 0 -1px 9px, $ledYellowShadowSecond 0 2px 5px;
  }

  &-blue {
    background-color: $info;
    box-shadow: $ledBaseShadow 0 -1px 7px 1px, inset $ledBlueShadowFirst 0 -1px 9px, $ledBlueShadowSecond 0 2px 5px;
  }
}

.strong {
  font-size: 1.2em;
  font-style: italic;
  color: $primaryColor;
}

.input--only-read {
  textarea {
    color: #000000;
    -webkit-text-fill-color: #000000 !important;
    opacity: 1;
  }
}

.notFound {
  &-container {
    background-color: "#fff";
    border: 5px solid $secondaryColor;
    border-radius: 10px;
    height: 60vh;
    margin-top: 20px;
  }
  &-icon {
    font-size: 4rem;
    color: $primaryColor;
    margin: 0px;
    font-weight: bolder;
  }
  &-text {
    font-size: 2rem;
    color: $primaryColor;
    margin: 0px;
  }
}

.backIconButton,
.backIcon {
  color: $secondaryColor !important;
}

.permissions-management-page--museums-list-container {
  overflow-y: scroll;
  max-height: 200px;
}

.create-external-tour-page-fields {
  min-height: 40px;
  padding-top: 8.5px;
  padding-bottom: 8.5px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
